.vidReso_select {
    text-align: left;
    padding: 10px;
    background-color: rgb(238, 237, 237);
    margin-bottom: 10px;
}

.vidReso_select > p {
    margin-top: 0px;
    margin-bottom: 0px;
}

.removeResoButton {
    border: none;
    padding: 0px;
}

.editVidRemoveReso,
.removeResoButton {
    font-size: 12px;
    color: rgb(100, 100, 100);
}

.EditVideosForm_buttons {
    margin-top: 20px;
}

#deleteButton {
    border: 1px solid rgb(133, 133, 133);
    border-radius: 3px;
    background-color: rgb(252, 62, 62);
    padding: 2px 5px 2px 5px;
    color: white;
    text-decoration: none;
}