.VideoBlock {
    width: 90%;
    margin: 0px auto 20px auto;
    padding: 20px 10px 10px 10px;
    border: 1px solid rgb(210, 210, 210);
    box-shadow: 4px 4px 2px -2px rgb(192, 192, 192);
    border-radius: 3px;
}

.videoBlockImage {
    margin-right: auto;
    margin-left: auto;
    width: 70%;
    border: 1px solid gray;
}

.VideoBlock_details {
    margin-right: auto;
    margin-left: auto;
    width: 95%;
    padding: 5px;
}

.videoBlockLink {
    text-decoration: none;
}

.videoBlockPostDate {
    font-size: 12px;
    color: rgb(120, 120, 120);
}

/*tablet adjustments*/
@media (min-width: 768px) {
    .videoBlockDesc {
        padding-left: 20px;
        padding-right: 20px;
    }
}

/*desktop adjustments*/
@media (min-width: 1200px) {
    .VideoBlock {
        display: flex;
        flex-direction: row;
    }

    .VideoBlock:hover {
        border: 2px solid rgb(210, 210, 210);
        box-shadow: 8px 8px 2px -2px rgb(192, 192, 192);
    }

    .VideoBlock_details {
        text-align: left;
        padding: 0px 20px 0px 20px;
    }

    .videoBlockImage {
        height: 90%;
        margin-top: 10px;
    }

    .videoBlockTitle {
        font-size: 22px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .videoBlockDesc {
        padding: 0px;
        margin: 5px auto 5px auto;
    }

    .videoBlockPostDate {
        font-size: 12px;
    }
}