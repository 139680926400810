.UserLogin {
    width: 60%;
    margin: auto auto 50px auto;
    text-align: left;
}

.UserLogin_formField {
    margin-bottom: 20px;
}

.UserLogin_formField > label {
    display: inline-block;
    width: 100%;
}

.UserLogin_formField > input {
    width: 100%;
    padding: 2px 5px 2px 5px;
}

.UserLogin_formField > p {
    margin-top: 0px;
    width: 100%;
}

.userLoginForgotPassword {
    color: rgb(66, 66, 66);
    font-size: 12px;
}

.userLogin_formCredError {
    color: red;
}

.UserLoginForm_buttons > button {
    margin-right: 10px;
}

/* tablet adjustments */
@media (min-width: 768px) {
    .UserLogin {
        width: 40%;
    }

    .UserLogin > h1 {
        text-align: center;
    }
}

/* desktop adjustments */
@media (min-width: 1200px) {
    .UserLogin {
        width: 20%;
    }
}