.VideoResources {
    text-align: left;
}

.videoResourcesDesc {
    margin-bottom: 0px;
}

.videoResourcesLink {
    font-size: 14px;
    margin-top: 0px;
}

/*tablet and desktop adjustments*/
@media (min-width: 800px) {
    .VideoResources {
        text-align: left;
    }
}