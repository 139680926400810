.Homepage {
    width: 100%;
}

.Homepage_desktopFeature,
.homepageSpacer {
    display: none;
}

.homepageBackgroundImage {
    width: 100%;
    opacity: 0.1;
}

.Homepage_background {
    margin-top: 0px;
    background-image: url('https://user-images.githubusercontent.com/58446465/107443385-94224780-6aed-11eb-9680-837a25b7c229.png');
    background-size: 100%;
    background-repeat: no-repeat;
}

.homepageBannerText {
    font-size: 30px;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.homepageImage {
    position: relative;
    top: -25px;
    width: 125px;
    height: 125px;
    border-radius: 90px;
    border: 2px solid white;
    margin-bottom: 0px;
}

.homepageAbout {
    position: relative;
    top: -20px;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    border-top: 1px solid rgb(195, 195, 195);
    border-bottom: 1px solid rgb(195, 195, 195);
}

.homepageAbout > p {
    white-space: pre-wrap;
}

.RecentVideos {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.recentVideosTitle {
    display: block;
    width: 100%;
}

/* tablet adjustments */
@media (min-width: 768px) {
    .homepageBannerText {
        font-size: 40px;
        padding-left: 8%;
    }

    .Homepage_about {
        display: flex;
        flex-direction: row;
        width: 80%;
        padding: 10px;
        margin: 20px auto 20px auto;
        background-color: rgb(235, 235, 235);
        border-bottom-left-radius: 90px;
        border-top-left-radius: 90px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .homepageImage {
        width: 150px;
        height: 150px;
        top: auto;
    }

    .homepageAbout {
        font-size: 18px;
        border: none;
        top: auto;
        padding: 20px;
    }

    .homepageAbout > p {
        margin: 10px auto 10px auto;
    }

    .recentVideosTitle {
        font-size: 24px;
    }
}

/* desktop adjustments */
@media (min-width: 1200px) {
    .Homepage {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 0px;
    }

    .homepage_aboutDesktopAdj {
        width: 34%;
        height: 100vh;
    }

    .homepageImage {
        margin-top: auto;
        margin-bottom: auto;
    }
    
    .homepageAbout {
        font-size: 16px;
    }

    .Homepage_desktopFeature {
        display: inline-block;
        width: 90%;
        border-top: 1px solid rgb(195, 195, 195);
    }

    .Homepage_desktopFeature > p {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .homepageVidLink {
        text-decoration: none;
    }

    .homepageLatestVidThumb {
        width: 80%;
    }

    .latestVidDate {
        font-size: 12px;
        color: rgb(180, 180, 180);
        text-align: left;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 10px;
    }

    .homepageSpacer {
        display: inline-block;
        background-color: rgb(217, 217, 217, 0.75);
        width: 0.5%;
    }

    .RecentVideos {
        width: 65%;
        margin: 20px 0px auto auto;
    }

    .recentVideosTitle {
        font-size: 24px;
        border: none;
        margin-top: 0px;
    }
}