.About {
    width: 90%;
    margin: 20px auto 50px auto;
}

.About_introHeader {
    text-align: center;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    background-color: rgb(210, 210, 210);
}

.aboutImage {
    display: none;
}

.aboutEmailLink {
    color: black;
    text-decoration: none;
}

.About_info {
    margin-bottom: 50px;
}

.About_info > div {
    width: 90%;
    margin: 0px auto 30px auto;
}

.aboutInfoHeader {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid rgb(195, 195, 195);
}

.aboutFieldText {
    white-space: pre-wrap;
}

/* tablet adjustments */
@media (min-width: 768px) {
    .About_introHeader {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: auto auto 20px auto;
        border-bottom-left-radius: 90px;
        border-top-left-radius: 90px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    
    .aboutImage {
        display: inline-block;
        width: 125px;
        height: 125px;
        border-radius: 90px;
        border: 2px solid rgb(195, 195, 195);
    }

    .About_introHeader > p {
        font-size: 18px;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .About_info {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: auto;
    }

    .About_info > div {
        width: 32%;
        padding: 10px 20px 10px 20px;
        background-color: rgb(245, 245, 245);
    }

    .aboutInfoCompany {
        margin-left: 2%;
        margin-right: 2%;
    }

    .aboutInfoHeader {
        border: none;
    }
}

/* desktop adjusments */
@media (min-width: 1200px) {
    .About_introHeader,
    .About_info {
        width: 60%;
    }

    .About_introHeader {
        padding: 10px 20px 10px 10px;
    }

    .About_introHeader > p {
        font-size: 20px;
    }
}