.ContactUsForm {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    text-align: center;
    border: 1px solid rgb(195, 195, 195);
    border-radius: 5px;
}

.contactUsFormHeader {
    position: relative;
    text-align: center;
    top: -1.4em;
    display: inline;
    background-color: white;
    padding: 0px 5px 0px 5px;
}

.ContactUsForm_formField {
    text-align: left;
    margin-bottom: 20px;
}

.ContactUsForm_formField > label {
    display: inline-block;
    width: 100%;
}

.ContactUsForm_formField > input {
    padding: 2px 5px 2px 5px;
}

.contactUsMessage {
    height: 100px;
}

.contactUsConfirmSend {
    font-size: 14px;
    margin-top: 20px;
}

.ContactUsForm_formField > input,
.ContactUsForm_formField > textarea {
    width: 100%;
    border: 1px solid rgb(195, 195, 195);
    border-radius: 5px;
}

.ContactUsFormForm_buttons {
    text-align: left;
}

.ContactUsFormForm_buttons > button {
    margin-right: 10px;
}

/* tablet adjustments */
@media (min-width: 768px) {
    .ContactUsForm {
        width: 78%;
    }
}

/* desktop adjustments */
@media (min-width: 1200px) {
    .ContactUsForm {
        width: 40%;
        margin: 20px auto auto auto;
        padding: 0px 50px 20px 50px;
    }

    .contactUsFormHeader {
        top: -0.85em;
    }
}