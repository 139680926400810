.RelatedVidBlock {
    width: 90%;
    margin: 0px auto 10px auto;
    padding: 20px 10px 10px 10px;
    background-color: rgb(245, 245, 245);
}

.relatedVidBlockImage {
    margin-right: auto;
    margin-left: auto;
    width: 70%;
    border: 1px solid gray;
    box-shadow: 4px 4px 2px -2px rgb(192, 192, 192);
}

.RelatedVidBlock_details {
    margin-right: auto;
    margin-left: auto;
    width: 80%;
    padding: 5px;
}

.relatedVidBlockLink {
    text-decoration: none;
}

.relatedVidBlockPostDate {
    font-size: 14px;
    color: rgb(120, 120, 120);
}

/*tablet adjustments*/
@media (min-width: 768px) {
    .RelatedVidBlock {
        display: flex;
        flex-direction: row;
        margin: 0px auto 0px auto;
    }

    .relatedVidBlockImage {
        width: 23%;
        height: 90%;
        margin-top: 10px;
    }

    .RelatedVidBlock_details {
        text-align: left;
        padding: 0px 10px 0px 20px;
    }

    .relatedVidBlockTitle {
        margin: 0px auto 0px auto;
    }

    .relatedVidBlockDesc {
        font-size: 14px;
        margin: 0px auto 5px auto;
    }

    .relatedVidBlockPostDate {
        font-size: 12px;
        margin-top: 0px;
    }
}

/* desktop adjustments */
@media (min-width: 1200px) {
    .relatedVidBlockImage {
        width: 70%;
        height: 90%;
        margin-top: 10px;
    }

    .relatedVidBlockTitle {
        margin: 0px auto 10px auto;
    }

    .RelatedVidBlock_details {
        height: 50%;
        margin-top: auto;
        margin-bottom: auto;
        padding: 0px 20px 0px 0px;
    }
}