.ResetPassword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.ResetPassword > h1 {
    text-align: center;
}
 
.ResetPassword_formField {
    margin-bottom: 20px;
}

.ResetPassword_formField > label {
    display: inline-block;
    width: 100%;
}

.ResetPassword_formField > p {
    margin-top: 0px;
}

.errorText {
    color: red;
    font-size: 12px;
}

/* tablet adjustments */
@media (min-width: 768px) {
    .ResetPassword {
        width: 50%;
    }
}

@media (min-width: 1200px) {
    .ResetPassword {
        width: 30%;
    }
}