.Topnav {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    border-bottom: 1px solid rgb(198, 198, 198);
    font-weight: bold;
    padding: 5px 5px 5px 5px;
    line-height: 40px;
    width: 100%;
    direction: rtl;
    position: relative;
}

.topNavLogo {
    display: none;
}

.topNavHeader {
    margin-left: 10px;
}

.topnavHeaderLink {
    font-size: 18px;
    color: rgb(84, 84, 84);
    font-weight: bold;
    text-decoration: none;
}
  
.menu {
    margin-right: 0px;
    margin-left: auto;
    direction: ltr;
    min-width: 50px;
    padding: 5px 5px 5px 5px;
}

.TopNav_contentContainer {
    position: absolute;
    right: 0;
    top: 100%;
    width: 150px;
    z-index: 1;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    border-left: 2px solid white;
    background-color: rgb(235, 235, 235, 0.95);
    text-align: right;
    margin-right: 10px;
}
  
.Topnav ul {
    list-style: none;
    margin: 0px 0px 0px auto;
    padding-right: 10px;
    padding-left: 0px;
    direction: ltr;
}

.menulink {
    text-decoration: none;
    color: rgb(84, 84, 84);
}
  
.hamburger {
    margin-left: auto;
    width: 20px;
    font-size: 18px;
    font-weight: bold;
}
    
.hamburger:hover {
    cursor: pointer;
    display: block;
    right: 0;
    left: auto;
    top: 10px;
    visibility: visible;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.hamburgerButton,
.topNavLink {
    color: rgb(84, 84, 84);
    border: none;
    text-decoration: none;
    font-size: .90em;
}

.hamburgerButton {
    background-color: white;
}

button:hover,
.menulink {
    cursor: pointer;
}

.TopNav_socialIcons {
    display: flex;
    flex-direction: row;
    padding-right: 10px;
    padding-top: 10px;
}

.topNavSocialIcon {
    margin-left: 10px;
}

.topNavSocialImage {
    height: 20px;
}

.show {
    margin-right: 0px;
    margin-left: auto;
}

.hidden {
    display: none;
}

@media (min-width: 1200px) {
    .Topnav {
        width: 100%;
        background-color: white;
        border-bottom: solid 1px rgb(217, 217, 217);
        padding: auto 20px auto 20px;
    }

    .browseVids {
        position: fixed;
        top: 0;
        left: 0;
    }

    .topNavLogo {
        display: inline-block;
        margin-left: 30px;
        padding-top: 5px;
    }
    
    .topNavLogoImage {
        width: 50px;
    }

    .break {
        display: none;
    }

    .topNavHeader {
        margin-left: 10px;
        margin-right: 10px;
        line-height: 65px;
    }

    .topnavHeaderLink {
        font-size: 22px;
        color: rgb(84, 84, 84);
    }

    .hamburger,
    .hamburgerButton {
        display: none;
    }

    .show,
    .hidden {
        display: inline-block;
        width: 50%;
    }

    .TopNav_contentContainer {
        position: unset;
        display: flex;
        flex-direction: row-reverse;
        border: none;
        text-align: left;
        width: 100%;
        background-color: white;
    }

    .Topnav ul {
        display: flex;
        flex-direction: row;
        margin: auto auto auto 10px;
        line-height: 65px;
        padding: 0px;
    }

    .Topnav li {
        display: inline-block;
        padding-left: 20px;
    }

    .topNavLink {
        font-size: 16px;
        color: rgb(84, 84, 84);
        background-color: white;
    }

    .TopNav_socialIcons {
        position: absolute;
        right: 0;
        top: 15px;
        display: flex;
        flex-direction: row;
        margin-right: 30px;
    }

    .topNavSocialImage {
        height: 25px;
    }
}