.BrowseVideos {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.BrowseVideos_desktopFeature {
    display: none;
}

.BrowseVideos_form {
    width: 80%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

.browseVideos_desktopAdj > h1 {
    font-size: 20px;
}

.BrowseVideos > form {
    margin-bottom: 20px;
}

.BrowseVideos_form > label {
    margin-right: 10px;
}

#search {
    width: 100%;
    margin-bottom: 20px;
}

.browseVidsFilter {
    display: inline-block;
    width: 100%;
}

#tagsRef {
    margin-bottom: 20px;
    color: rgb(120, 120, 120);
    font-size: 16px;
    border: 1px solid rgb(200, 200, 200);
    border-radius: 3px;
    padding: 5px;
    background-color: white;
}

@media (min-width: 768px) {
    .BrowseVideos_form {
        width: 60%;
    }

    .browseVideosFilterOptions {
        width: 90%;
        display: flex;
        flex-direction: row;
        margin: auto auto 20px auto;
        text-align: left;
    }

    .filterOptionsText {
        color: gray;
        font-size: 14px;
        line-height: 30px;
        margin: 0px 10px 0px auto;
    }

    .filterOptionButton {
        padding: 0px;
        margin-left: 5px;
    }

    .filterOptionButton:hover {
        cursor: pointer;
    }

    .filterOptionImage {
        width: 30px;
        height: 30px;
        margin-right: 5px;
    }

    .showGrid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1200px) {
    .BrowseVideos {
        display: flex;
        flex-direction: row;
        margin-top: 6%;
        margin-bottom: 20px;
    }

    .BrowseVideos_formInfo {
        width: 35%;
        margin-right: auto 20px auto auto;   
    }

    .browseVideos_desktopAdj {
        position: fixed;
        width: 30%;
        border-right: 1px solid gray;
    }

    .BrowseVideos_desktopFeature {
        display: inline-block;
        width: 90%;
        border-top: 1px solid rgb(195, 195, 195);
    }

    .browseVideos_desktopAdj > h1 {
        font-size: 24px;
    }

    .browseVidsLatestVidDesc {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .browseVideosVidLink {
        text-decoration: none;
    }

    .browseVideosLatestVidThumb {
        width: 90%;
    }

    .browseVidsLatestVidDate {
        font-size: 12px;
        color: rgb(180, 180, 180);
        text-align: left;
        margin: 0px auto 0px 5%;
    }

    .browseVideosHeader {
        font-size: 24px;
    }

    .BrowseVideos_videos {
        width: 65%;
    }
}