.AdminVideos {
    width: 60%;
    margin: auto auto 30px auto;
}

.adminVideosHeader {
    border-bottom: 1px solid rgb(195, 195, 195);
}

.AdminVideos_form > label {
    display: inline-block;
    width: 100%;
}

.adminVideos_formInput {
    width: 100%;
    margin-bottom: 20px;
}

.adminVideos_formInput > input,
.adminVideos_formInput > textarea {
    width: 80%;
}

.adminVideos_formInput > textarea {
    height: 100px;
}

.tagsRefHelperText {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 14px;
}

.dateHelperText {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 14px;
}

#addVideosTags {
    width: 60%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

.addVideosAddTags {
    border: none;
    background-color: white;
    text-decoration: underline;
}

.adminVideos_formResourcesMore {
    margin-bottom: 20px;
}

.AddDestinationForm_buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.formError {
    display: inline-block;
    width: 100%;
    font-size: 12px;
    color: red;
}

@media (min-width: 768px) {
    .AddDestinationForm_buttons {
        width: 40%;
    }
}

@media (min-width: 1200px) {
    .AdminVideos {
        width: 40%;
    }

    .AddDestinationForm_buttons {
        width: 20%;
    }

    .AdminVideos_form > .AdminVideos_formResources {
        width: 80%;
    }
}