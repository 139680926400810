.EditSiteText {
    width: 90%;
    margin: auto auto 30px auto;
}

.EditSiteText_form > h3 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid rgb(210, 210, 210);
}

.currTextHeader {
    font-size: 14px;
    color: rgb(120, 120, 120);
    margin-bottom: 0px;
}

.currText {
    font-size: 12px;
    color: rgb(120, 120, 120);
    margin-top: 0px;
}

.EditSiteText_formField,
.EditSiteTextForm_buttons {
    width: 70%;
    margin: auto auto 20px auto;
    text-align: left;
}

.EditSiteText_formField > label {
    display: inline-block;
    width: 100%;
    font-weight: bold;
}

.EditSiteText_formField > textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 0px;
}

.EditSiteText_formField > .validationError {
    margin-left: 0px;
}

.editTextValidError {
    font-size: 12px;
    color: red;
}

/* desktop adjustments */
@media (min-width: 1200px) {
    .EditSiteText {
        width: 35%;
    }

    .EditSiteText_form > h3 {
        width: 50%;
    }

    .EditSiteText_formField,
    .EditSiteTextForm_buttons {
        width: 100%;
    }
}