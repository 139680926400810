.CreateUser {
    width: 60%;
    text-align: left;
    margin: auto auto 50px auto;
}

#createUserWhyAccount {
    border: none;
    background-color: white;
    padding: 0px;
    text-decoration: underline;
}

#createAccountWhyDetails {
    background-color: rgb(230, 230, 230);
    border-radius: 3px;
    padding: 5px 15px 5px 15px;
}

#hiddenAcctDetails {
    display: none;
}

.CreateUser_form {
    width: 100%;
    margin: 20px auto 50px auto;
    text-align: left;
}

.CreateUser_formField {
    margin-bottom: 20px;
}

.CreateUser_formField > label {
    display: inline-block;
    width: 100%;
}

.CreateUser_formField > input{
    width: 100%;
    padding: 2px 5px 2px 5px;
}

#createAccountPassDetails {
    font-size: 12px;
    margin-top: 0px;
}

.createUserError {
    font-size: 12px;
    color: red;
}

.CreateUserForm_buttons > button {
    margin-right: 10px;
}

.createUserToggleChar {
    font-size: 12px;
    border: none;
    background-color: white;
    padding: 0px;
}

/* tablet adjustments */
@media (min-width: 768px) {
    .CreateUser {
        width: 30%;
    }

    .CreateUser > h1 {
        text-align: center;
    }
}

/* desktop adjustments */
@media (min-width: 1200px) {
    .CreateUser {
        width: 20%;
    }
}