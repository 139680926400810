.AddComment {
    padding-top: 20px;
    border-top: 1px solid rgb(210, 210, 210);
}

.AddComment_form {
    text-align: left;
}

.AddComment_form > label {
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
}

.AddComment_form > textarea {
    width: 100%;
}

.AddComment_form > .validationError {
    margin-left: 0px;
    margin-right: auto;
}

#commentError {
    font-size: 12px;
    color: red;
}

.AddCommentForm_buttons {
    margin-top: 20px;
}

/*tablet adjustments*/
@media (min-width: 768px) {
    .AddComment {
        width: 95%;
        border-top: 1px solid gray;
        padding-top: 10px;
        text-align: left;
    }

    .AddComment_form > input {
        width: 50%;
        height: 5em;
    }
}

/* desktop adjustments */
@media (min-width: 1200px) {
    .AddComment_form > textarea {
        width: 50%;
    }
}