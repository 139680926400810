.SearchedVid {
    border-radius: 3px;
    width: 90%;
    margin: 0px auto 20px auto;
    padding: 10px;
    background-color: rgb(230, 230, 230);
}

.searchedVidImage {
    margin-right: auto;
    margin-left: auto;
    width: 70%;
    border: 1px solid gray;
}

.SearchedVid_details {
    margin-right: auto;
    margin-left: auto;
    width: 80%;
    padding: 5px;
}

.searchedVidLink {
    text-decoration: none;
}

.searchedVidClear {
    width: 100%;
    text-align: right;
}

.searchedVidClear > button {
    border: none;
    background-color: rgb(230, 230, 230);
    margin-bottom: 10px;
}

.searchedVidPostDate {
    font-size: 12px;
    margin-top: 0px;
    color: rgb(120, 120, 120);
}

/*desktop adjustments*/
@media (min-width: 1200px) {
    .SearchedVid {
        padding: 10px 20px 20px 20px;
    }

    .SearchedVid:hover {
        border: 2px solid rgb(210, 210, 210);
        box-shadow: 8px 8px 2px -2px rgb(192, 192, 192);
    }

    .SearchedVid_content {
        display: flex;
        flex-direction: row;
    }

    .searchedVidClear > button {
        margin-bottom: 0px;
    }

    .SearchedVid_details {
        text-align: left;
        padding: 0px 20px 0px 20px;
    }

    .searchedVidImage {
        height: 90%;
        margin-top: 10px;
    }

    .searchedVidTitle {
        font-size: 22px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .searchedVidDesc {
        margin: 5px auto 5px auto;
    }
}