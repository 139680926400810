.tempPasswordConfirmText {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.TempPasswordConfirm_form {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.TempPasswordConfirm_formField {
    margin-bottom: 20px;
}

.TempPasswordConfirm_formField > label {
    display: inline-block;
    width: 100%;
}

.TempPasswordConfirm_formField > p {
    margin-top: 0px;
}

.errorText {
    color: red;
    font-size: 12px;
}

@media (min-width: 800px) {
    .TempPasswordConfirm {
        text-align: center;
    }
}