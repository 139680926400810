.UserProfile {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.UserProfile_info,
.UserProfile_manage {
    text-align: left;
}

.userProfileSubHeader {
    display: inline-block;
    width: 100%;
    font-weight: bold;
    color: rgb(75, 75, 75);
    border-bottom: 1px solid gray;
}

.userProfileLink {
    font-size: 14px;
}

.userProfileDelete {
    border: none;
    background-color: white;
    padding: 0px;
    text-decoration: underline;
}

.modal {
    font-size: 12px;
  }
  .modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
  }
  .modal > .content {
    width: 100%;
    padding: 10px 5px;
  }
  .modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
  }
  .modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }

/* tablet adjustments */
@media (min-width: 768px) {
  .UserProfile {
    width: 50%;
  }
}

/* desktop adjustments */
@media (min-width: 1200px) {
  .UserProfile {
    width: 30%;
  }
}