.MainVideoPage {
    width: 90%;
    margin: 20px auto 30px auto;
}

.mainVideoPageVideo {
    position: relative;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 56.25%;
    padding-top: 25px;
}

.mainVideoPageVideo > iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.mainVideoPageTitle {
    font-size: 20px;
    margin-bottom: 0px;
}

.mainVideoDetails {
    display: flex;
    flex-direction: row;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.mainVideoDetails > p {
    font-size: 12px;
    color: rgb(120, 120, 120);
    line-height: 30px;
}

.mainVideoDate {
    margin: 0px auto 0px 0px;
}

.mainVideoShare {
    margin: 0px 0px 0px auto;
}

.mainVideoShareIcons {
    margin: 5px 0px 0px 0px;
}

.socialShareButton {
    margin-left: 5px;
}

.mainVideoDesc {
    padding: 10px;
    margin-bottom: 30px;
    text-align: left;
    white-space: pre-wrap;
}

.MainVideoPage_resources,
.MainVideoPage_comments,
.MainVideoPage_relatedVids {
    border: 1px solid rgb(210, 210, 210);
    border-radius: 3px;
    margin-bottom: 20px;
}

.MainVideoPage_resources,
.MainVideoPage_comments {
    padding: 0px 20px 10px 20px;
}

.mainVideoPage_resourcesHeader,
.mainVideoPage_commentsHeader,
.mainVideoPage_relatedVidsHeader {
    position: relative;
    top: -0.75em;
    display: inline;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 5px 0px 5px;
}

.mainVideoPageLoginPrompt {
    text-align: left;
    font-size: 14px;
}

.MainVideoPage_admin {
    font-size: 14px;
}

.firstToComment {
    font-size: 14px;
    color: rgb(120, 120, 120);
}

/* tablet adjustments */
@media (min-width: 768px) {
    .mainVideoPage_featureAbout {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
    }

    .mainVideoDesc {
        padding: 0px;
        margin-bottom: 30px;
    }
}

/* desktop adjustments*/
@media (min-width: 1200px) {
    .MainVideoPage {
        display: flex;
        flex-direction: row;
        padding-top: 30px;
    }

    .MainVideoPage_feature {
        width: 60%;
        padding-right: 20px;
    }

    .mainVideoPage_featureAbout {
        text-align: left;
        padding-left: 20px;
        width: 100%;
    }

    .mainVideoPageTitle {
        font-size: 28px;
        margin-bottom: 0px;
    }

    .mainVideoDate {
        display: inline-block;
        margin-bottom: 0px;
    }

    .mainVideoDesc {
        padding: 0px;
        margin-bottom: 30px;
    }

    .MainVideoPage_resources,
    .MainVideoPage_comments {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        padding: 0px 0px 30px 20px;
    }

    .MainVideoPage_relatedVids {
        width: 40%;
    }

    .mainVideoPage_relatedVidsMore {
        text-align: left;
        margin-left: 7%;
    }
}