.ChangePassword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.ChangePassword > h1 {
    text-align: center;
}

.changePasswordFieldToggle {
    border: none;
    background-color: white;
    font-size: 12px;
    padding: 0px;
}

.ChangePassword_formField {
    margin-bottom: 20px;
}

.ChangePassword_formField > label {
    display: inline-block;
    width: 100%;
}

.ChangePassword_formField > p {
    margin-top: 0px;
}

.errorText {
    color: red;
    font-size: 12px;
}

/* tablet adjustments */
@media (min-width: 768px) {
    .ChangePassword {
        width: 40%;
    }
}

/* desktop adjustments */
@media (min-width: 1200px) {
    .ChangePassword {
        width: 30%;
    }
}