.AdminVideos_formResources {
    width: 100%;
    background-color: rgb(233, 233, 233);
    margin: 0px auto 10px auto;
    padding: 10px;
    text-align: left;
}

.AdminVideos_formResources > label {
    display: inline-block;
    width: 100%;
}

.AdminVideos_formResources > input {
    width: 100%;
    margin-bottom: 10px;
}

.vidResoRemoveButton {
    border: none;
    color: gray;
    font-size: 12px;
    padding: 0px;
}